import { ReactElement, ReactNode, useEffect, useState } from 'react'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import { NextPage } from 'next'
import { Session } from 'next-auth'
import { SessionProvider } from 'next-auth/react'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import { theme } from 'common/utils/theme'
import eventEmitter from 'common/utils/event-emitter'
import ApiProviderContext from 'common/context/api-provider-context'
import ImageKitContext from 'common/context/image-kit-context'
import { DateContextProvider } from 'common/context/date-context'
import AlertSnackbar, { IAlert } from 'common/components/ui/alert-snackbar'
import { GlobalSearchContextProvider } from 'common/context/global-search-context'
import { GlobalFilterContextProvider } from 'common/context/global-filter-context'
import PrintLayout from 'common/components/layout/print-layout'
import { CaslAbilityContextProvider } from 'common/context/casl-ability-context'
import { AppContextProvider } from 'common/context/app-context'

import 'global.css'
import 'modules/@plate/styles/globals.css'
import FreshdeskWidget from 'modules/freshdesk/components/freshdesk-widget'
import { useRouter } from 'next/router'

const NO_AUTH_ROUTES = ['/auth-login']

type NextAuthSession = { session: Session }
export type NextPageWithLayout = NextPage<NextAuthSession> & {
  getLayout?: (page: ReactElement) => ReactNode
}
type AppPropsWithLayout = AppProps<NextAuthSession> & {
  Component: NextPageWithLayout
}

const App = ({ Component, pageProps }: AppPropsWithLayout) => {
  const { route } = useRouter()
  const [alert, setAlert] = useState<IAlert>({} as IAlert)

  useEffect(() => {
    eventEmitter.addListener('onAlert', (alt: IAlert) => setAlert({ ...alt, open: true }))
    return () => eventEmitter.removeAllListeners('onAlert')
  }, [])

  return (
    <>
      <Head>
        <title>Moments</title>
        <meta name='moments' content='moments' />
        <meta name='viewport' content='width=device-width, initial-scale=1.0, user-scalable=no' />
        <link rel='icon' type='images/png' href='/favicon.ico' />
      </Head>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <AlertSnackbar
          open={alert.open}
          type={alert.type}
          message={alert.message}
          onClose={() => setAlert({ ...alert, open: false, message: '' })}
        />

        <SessionProvider session={pageProps.session} refetchInterval={0}>
          <ApiProviderContext>
            {/* TODO: find better rendering for no context needed pages */}
            {NO_AUTH_ROUTES.includes(route) && <Component {...pageProps} />}

            {!NO_AUTH_ROUTES.includes(route) && (
              <>
                <AppContextProvider>
                  <DateContextProvider>
                    <CaslAbilityContextProvider>
                      <GlobalSearchContextProvider>
                        <GlobalFilterContextProvider>
                          <ImageKitContext>
                            <PrintLayout>
                              <FreshdeskWidget />
                              {Component.getLayout ? (
                                Component.getLayout(<Component {...pageProps} />)
                              ) : (
                                <Component {...pageProps} />
                              )}
                            </PrintLayout>
                          </ImageKitContext>
                        </GlobalFilterContextProvider>
                      </GlobalSearchContextProvider>
                    </CaslAbilityContextProvider>
                  </DateContextProvider>
                </AppContextProvider>
              </>
            )}
          </ApiProviderContext>
        </SessionProvider>
      </ThemeProvider>
    </>
  )
}

export default App
